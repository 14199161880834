import '../css/demo.css';

const tryInitGame = () => {
  setTimeout(() => {
    if (coinGameOP.ready()) {
      coinGameOP.show();
    } else {
      tryInitGame();
    }
  }, 300);
};

tryInitGame();
